import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import Button from 'components/Button';

import './_form.scss';

const GETFORM_URL = 'https://getform.io/f/8d8e5bc6-1a24-41b9-b2b3-4642e9b6d48a';

const Form = () => {
  const [submitted, setSubmitted] = useState(false);
  const { register, errors, handleSubmit, setError, reset } = useForm();

  const onSubmit = async data => {
    try {
      fetch(GETFORM_URL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      setSubmitted(true);
      reset();
    } catch (error) {
      setError(
        'submit',
        'submitError',
        `Oops! Įvyko serverio klaida :( ${error.message}`
      );
    }
  };

  const showSubmitError = msg => <p className="form__msg-error">{msg}</p>;

  const showThankYou = (
    <div className="form__msg-confirm-container">
      <p className="form__msg-confirm">
        Ačiū! Jūsų žinutė sėkmingai išsiųsta. Su Jumis susisieksime artimiausiu
        metu.
      </p>
      <Button
        type="button"
        appearance="alfa"
        onClick={() => setSubmitted(false)}
      >
        Siųsti dar vieną?
      </Button>
    </div>
  );

  const showForm = (
    <form onSubmit={handleSubmit(onSubmit)} method="post" className="form">
      <div className="form__row">
        <label htmlFor="name" className="form__label">
          Vardas
          <input
            type="text"
            name="name"
            id="name"
            className="form__input"
            ref={register({ required: 'Įveskite savo vardą' })}
          />
          {errors.name && (
            <div className="form__msg-error">{errors.name.message}</div>
          )}
        </label>
        <label htmlFor="email" className="form__label">
          El. paštas
          <input
            type="email"
            name="email"
            id="email"
            className="form__input"
            ref={register({ required: 'Įveskite savo el. paštą' })}
          />
          {errors.email && (
            <div className="form__msg-error">{errors.email.message}</div>
          )}
        </label>
      </div>
      <label htmlFor="message" className="form__label">
        Žinutė
        <textarea
          type="textarea"
          name="message"
          id="message"
          className="form__textarea"
          ref={register({ required: 'Nepamirškite palikti žinutę mums :)' })}
        />
        {errors.message && (
          <div className="form__msg-error">{errors.message.message}</div>
        )}
      </label>
      <Button type="submit" appearance="alfa">
        Siųsti
      </Button>
    </form>
  );

  return (
    <>
      {errors && errors.submit && showSubmitError(errors.submit.message)}
      {submitted ? showThankYou : showForm}
    </>
  );
};

export default Form;
