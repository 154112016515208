import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Navigation from 'components/Navigation';
import Social from 'components/Social';
import video from '../../assets/video/video.mp4';

import './_header.scss';

const Header = () => {
  const data = useStaticQuery(graphql`
    query Logo {
      file(relativePath: { eq: "logotipas.png" }) {
        childImageSharp {
          fluid(maxWidth: 246) {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `);

  return (
    <>
      <header className="header">
        <video className="header__video" loop muted autoPlay>
          <source src={video} type="video/mp4" />
        </video>
        <Navigation />
        <div className="header__image-container">
          <Img
            className="header__image"
            fluid={data.file.childImageSharp.fluid}
            alt="renginiu asai logotipas"
          />
        </div>
        <Social />
      </header>
    </>
  );
};

export default Header;
