import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Fade from 'react-reveal/Fade';

import './_navigation.scss';

const Navigation = () => {
  //To make links work with tabs. TODO: Change smoothsroll library, which would let to use hrefs inside a
  const handlePaslaugosClick = e => {
    if (e.key === 'Enter') {
      scrollTo('#paslaugos');
    }
  };

  const handleKomandaClick = e => {
    if (e.key === 'Enter') {
      scrollTo('#komanda');
    }
  };

  const handleKontaktaiClick = e => {
    if (e.key === 'Enter') {
      scrollTo('#kontaktai');
    }
  };

  return (
    <nav className="navigation">
      <Fade cascade left>
        <ul className="navigation__list">
          <li className="navigation__list-item">
            <a
              className="navigation__link"
              tabIndex="0"
              onKeyPress={handlePaslaugosClick}
              onClick={() => scrollTo('#paslaugos')}
            >
              Paslaugos
            </a>
          </li>
          <li className="navigation__list-item">
            <a
              className="navigation__link"
              tabIndex="0"
              onKeyPress={handleKomandaClick}
              onClick={() => scrollTo('#komanda')}
            >
              Komanda
            </a>
          </li>
          <li className="navigation__list-item">
            <a
              className="navigation__link"
              tabIndex="0"
              onKeyPress={handleKontaktaiClick}
              onClick={() => scrollTo('#kontaktai')}
            >
              Kontaktai
            </a>
          </li>
        </ul>
      </Fade>
      <time className="navigation__time">© {new Date().getFullYear()}</time>
    </nav>
  );
};

export default Navigation;
