import React from 'react';

import './_footer.scss';

const Footer = () => (
  <footer className="footer">
    <p className="footer__text">
      © <time> {new Date().getFullYear()}</time> Renginių Asai
    </p>
    <p className="footer__text">
      Svetainės autorius:{' '}
      <a
        href="https://tamulaitis.com"
        target="_blank"
        rel="noopener nofollowe"
        className="footer__link"
      >
        R. Tamulaitis
      </a>
    </p>
  </footer>
);

export default Footer;
