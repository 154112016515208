import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

import './_team-member.scss';

const TeamMember = ({
  name,
  para1,
  para2,
  para3,
  image,
  hoverImage,
  pitch,
  isReversed = false,
  ctaLink,
}) => {
  return (
    <div
      className={
        isReversed ? 'team-member team-member--reverse' : 'team-member'
      }
    >
      <div
        className={
          isReversed
            ? 'team-member__photo team-member__photo--front team-member__photo--reverse'
            : 'team-member__photo team-member__photo--front'
        }
      >
        {image}
        <div className="team-member__photo team-member__photo--back">
          {hoverImage}
        </div>
      </div>
      <div className="team-member__info">
        <Fade right>
          <h3 className="team-member__name">{name}</h3>
          <p className="team-member__about team-member__about--pitch">
            {pitch}
          </p>
          <p className="team-member__about">{para1}</p>
          <p className="team-member__about">{para2}</p>
          <div>{para3 && <p className="team-member__about">{para3}</p>}</div>
          <div>
            {ctaLink && (
              <a href={`mailto:${ctaLink}`} className="button button--beta">
                Teirautis užimtumo
              </a>
            )}
          </div>
        </Fade>
      </div>
    </div>
  );
};

TeamMember.propTypes = {
  name: PropTypes.string.isRequired,
  pitch: PropTypes.string,
  para1: PropTypes.string.isRequired,
  para2: PropTypes.string,
  para3: PropTypes.string,
  image: PropTypes.node.isRequired,
  hoverImage: PropTypes.node,
  isReversed: PropTypes.bool,
  ctaLink: PropTypes.string,
};

export default TeamMember;
