import React from 'react';

import Form from 'components/Form';
import ContactsList from 'components/ContactsList';

import './_contact.scss';

const items = [
  {
    id: 1,
    term: 'Telefonas:',
    desc: '+370 624 66878',
    isPhone: true,
  },
  {
    id: 2,
    term: 'El. paštas:',
    desc: 'info@renginiuasai.lt',
    isPhone: false,
  },
];

const Contact = () => {
  return (
    <div id="kontaktai" className="contact">
      <ContactsList
        heading="Susisiekime ir kurkime Jūsų šventę kartu!"
        list={items}
      />
      <Form />
    </div>
  );
};

export default Contact;
