import React from 'react';
import PropTypes from 'prop-types';

import './_contacts-list.scss';

const ContactsList = ({ heading, list }) => {
  return (
    <div className="contacts-list">
      <h3 className="contacts-list__heading">{heading}</h3>
      <dl className="contacts-list__list">
        {list &&
          list.map(item => (
            <React.Fragment key={item.id}>
              <dt className="contacts-list__term">{item.term}</dt>
              <dd className="contacts-list__desc">
                {item.isPhone ? (
                  <a
                    className="contacts-list__link"
                    href={`tel:${item.desc}`}
                    rel="noopener noreferrer"
                  >
                    {item.desc}
                  </a>
                ) : (
                  <a
                    className="contacts-list__link"
                    href={`mailto:${item.desc}`}
                    rel="noopener noreferrer"
                  >
                    {item.desc}
                  </a>
                )}
              </dd>
            </React.Fragment>
          ))}
      </dl>
      <div className="contacts-list__social-wrapper">
        <a
          className="contacts-list__social"
          href="https://www.facebook.com/renginiuasai/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
        <a
          className="contacts-list__social"
          href="https://www.instagram.com/renginiuasai/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
        <a
          className="contacts-list__social"
          href="https://www.youtube.com/user/rageliukas"
          target="_blank"
          rel="noopener noreferrer"
        >
          Youtube
        </a>
      </div>
    </div>
  );
};

ContactsList.propTypes = {
  list: PropTypes.array.isRequired,
  heading: PropTypes.string,
};

export default ContactsList;
