import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

import './_service.scss';

const Service = ({ title, text, icon }) => (
  <Fade bottom>
    <div className="service">
      <div className="service__icon">{icon}</div>
      <h2 className="service__title">{title}</h2>
      <p className="service__text">{text}</p>
    </div>
  </Fade>
);

Service.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

export default Service;
