import React from 'react';
import Slide from 'react-reveal/Fade';

import './_social.scss';

const Social = () => (
  <Slide bottom delay={1000}>
    <div className="social">
      <a
        className="social__link"
        href="https://www.facebook.com/renginiuasai/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Facebook
      </a>
      <a
        className="social__link"
        href="https://www.instagram.com/renginiuasai/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Instagram
      </a>
      <a
        className="social__link"
        href="https://www.youtube.com/user/rageliukas"
        target="_blank"
        rel="noopener noreferrer"
      >
        Youtube
      </a>
    </div>
  </Slide>
);

export default Social;
