import React from 'react';
import PropTypes from 'prop-types';

import './_button.scss';

const Button = ({ appearance, type, children, onClick }) => (
  <button
    type={type}
    className={`button button--${appearance}`}
    onClick={onClick}
  >
    {children}
  </button>
);

Button.propTypes = {
  appearance: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default Button;
