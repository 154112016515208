import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import TeamMember from 'components/TeamMember';

import './_team.scss';

export const fluidImage = graphql`
  fragment fluidImage on File {
    id
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
const Team = () => {
  const data = useStaticQuery(graphql`
    query Images {
      evaldas: file(relativePath: { eq: "team/evaldas.jpg" }) {
        ...fluidImage
      }
      evaldas2: file(relativePath: { eq: "team/evaldas2.jpg" }) {
        ...fluidImage
      }
      mindaugas: file(relativePath: { eq: "team/mindaugas2.jpg" }) {
        ...fluidImage
      }
      mindaugas2: file(relativePath: { eq: "team/mindaugas.jpg" }) {
        ...fluidImage
      }
      edvinas: file(relativePath: { eq: "team/edvinas.jpg" }) {
        ...fluidImage
      }
      edvinas2: file(relativePath: { eq: "team/edvinas2.jpg" }) {
        ...fluidImage
      }
      vmindaugas: file(relativePath: { eq: "team/vmindaugas.jpg" }) {
        ...fluidImage
      }
      vmindaugas2: file(relativePath: { eq: "team/vmindaugas2.jpg" }) {
        ...fluidImage
      }
      rytis: file(relativePath: { eq: "team/rytis.jpg" }) {
        ...fluidImage
      }
      rytis2: file(relativePath: { eq: "team/rytis2.jpg" }) {
        ...fluidImage
      }
    }
  `);

  return (
    <div className="team" id="komanda">
      <TeamMember
        image={
          <Img
            fluid={data.evaldas.childImageSharp.fluid}
            alt="renginiu vedejas Evaldas Ragelis"
          />
        }
        hoverImage={
          <Img
            fluid={data.evaldas2.childImageSharp.fluid}
            alt="renginiu vedejas Evaldas Ragelis"
          />
        }
        name="Evaldas"
        pitch="Sveiki! Ieškote renginių vedėjo ir atlikėjo viename asmenyje? Tai aš – Evaldas Ragelis! "
        para1="Renginių vedėjo mikrofoną laikau nuo 2013 metų. Per šį laikotarpį esu turėjęs daugiau kaip 400 renginių, tarp kurių dažniausiai vyrauja vestuvės, gimtadieniai ir įmonių šventės. Mano vedami renginiai visuomet grįsti ypatingu dėmesiu, pasiruošimu ir svarbiausia, atsidavimu. Kūrybiškumas persipynęs su spontaniškumu lemia emocijų gausą, šypsenų ir neišdildomų įspūdžių."
        para2="Stengiuosi kiekvieną šventę padaryti išskirtine. Tai ne vien kūrybiška ir dinamiška šventinė tematika bet ir profesionalus gyvas muzikinis šou, kuris nustebina ir sutraukia svečius į šokių aikštelę. Dar daugiau, būdamas kūrybinių industrijų specialistu, galiu išpildyti tikrai visas kūrybines Jūsų šventės idėjas – tik pasakykite man apie tai."
        ctaLink="info@renginiuasai.lt"
      />
      <TeamMember
        isReversed={true}
        image={
          <Img
            fluid={data.mindaugas.childImageSharp.fluid}
            alt="saksofonistas Mindaugas Bivainis"
          />
        }
        hoverImage={
          <Img
            fluid={data.mindaugas2.childImageSharp.fluid}
            alt="saksofonistas Mindaugas Bivainis"
          />
        }
        name="Mindaugas"
        pitch="Sveiki, čia aš Mindaugas Bivainis. Dar vaikystėje į rankas atsitiktinai patekęs saksofonas iš manų rankų nedingsta jau ilgus metus."
        para1="Lietuvos muzikos ir teatro akademijoje įgijau magistro laipsnį, prisijungiau prie Kauno valstybinio muzikinio teatro saksofonų grupės narių, o dabar užburiančiais šio instrumento garsais su klausytojais dalinuosi ir įvairiuose renginiuose bei šventėse."
        para2="Saksofono melodija tampa laiko patikrinti bei pasaulinės šlovės susilaukę šiandienos hitai, tad mano repertuare sau patinkančią melodiją ras kiekvienas muzikos mylėtojas."
      />
      <TeamMember
        image={
          <Img
            fluid={data.edvinas.childImageSharp.fluid}
            alt="Dj Edvinas Matulaitis"
          />
        }
        hoverImage={
          <Img
            fluid={data.edvinas2.childImageSharp.fluid}
            alt="Dj Edvinas Matulaitis"
          />
        }
        name="Edvinas"
        pitch="Aš, DJ‘us Edvinas Matulaitis, esu pasirengęs išjudinti tave, net jeigu ir nemėgsti šokti."
        para1="Dar paauglystėje susižavėjau muzika ir nieko nelaukęs pradėjau organizuoti diskotekas bei kitokio tipo renginius mokyklose bei kultūros centruose. Patirtis pravertė ir vos pradėjus studijuoti turėjau darbo įvairiausiuose šalies klubuose bei privačiuose vakarėliuose."
        para2="Šešerius metus buvau palikęs Lietuvą, tačiau savo mylimo amato nemečiau – grojau vietiniuose klubuose ir baruose, vadovavau renginių techninio aptarnavimo veiklai Lietuvoje."
        para3="Grįžęs į gimtinę išbandžiau save naujame – vestuvių DJ‘aus – amplua, kuriame save sėkmingai realizuoju iki šiol. Neišsemiamoje fonotekoje mėgstamos muzikos atras kiekvienas, o norėdamas užtikrinti idealią šventę grojaraštį bei kitus rūpimus klausimus su klientu aptariu iš anksto."
      />
      <TeamMember
        isReversed={true}
        image={
          <Img
            fluid={data.vmindaugas.childImageSharp.fluid}
            alt="saksofonistas Mindaugas Bivainis"
          />
        }
        hoverImage={
          <Img
            fluid={data.vmindaugas2.childImageSharp.fluid}
            alt="saksofonistas Mindaugas Bivainis"
          />
        }
        name="Mindaugas"
        pitch="Susipažinkime, aš esu Mindaugas Rauktys. Nuo pat vaikystės mikrofonas man - natūrali gyvenimo detalė."
        para1="Pradedant pirmaisiais mokykliniais metais, baigiant paskutiniaisiais, tiek mokytojai, tiek klasės draugai, taipogi tėvai neįsivaizdavo manęs ne scenoje. Didžioji teatro scena, meninių bei raiškiojo skaitymo kompozicijų konkursai - buvo mano varikliukas."
        para2="Mokykliniai renginiai, profesionalios teatro vadovės man skiriami pagrindiniai vaidmenys, o vėliau ir miesto šventės, kuriose teko dalyvauti, kaip vedėju, tapo savotišku mano mėgstamos veiklos ir, greičiausiai, sugebėjimų įvertinimu. Kuo toliau, tuo labiau pradėjau sieti save su šia kultūra."
        para3="Šiuo metu džiaugiuosi kiekviena klientams suteikta gražia, linksma ir nepamirštama akimirka. Išnaudoju visas suteiktas galimybes sukurti žmonėms gražią šventę. Esu įsitikinęs, jog mano darbo pastangos prilygsta Jūsų lūkesčiams;)"
        ctaLink="rauktys.mindaugas@gmail.com"
      />
      <TeamMember
        image={
          <Img
            fluid={data.rytis2.childImageSharp.fluid}
            alt="fotografas Rytis Survila"
          />
        }
        hoverImage={
          <Img
            fluid={data.rytis.childImageSharp.fluid}
            alt="fotografas Rytis Survila"
          />
        }
        name="Rytis"
        pitch="Šypsokitės! Aš fotografas Rytis Survila esu pasiruošęs įamžinti Jūsų šventes ir renginius."
        para1="Nors esu vienos didžiausių šokių studijų savininkas bei profesionalus gatvės šokių treneris, savęs niekaip neatskiriu nuo foto meno puoselėjimo."
        para2="Pirmasis skaitmeninis fotoaparatas mano rankose atsidūrė prieš keturiolika metų. Šiandien turiu sukaupęs didelę patirtį net keliose meno šakose, o noras tobulėti mano akyse dega kaip niekada. Galimybė dirbti su aukštos klasės profesionalia fototechnika; klientų šypsenos, dėkingumas ir džiaugsmas matant savo darbo vaisius – tai didžiausias mano įkvėpėjas."
        para3="Na, gal dar ir meilė gyvūnams. Labradorė MeGusta – dažniausias mano fotomodelis ir visos bendravardės šokių studijos džiaugsmo šaltinis."
        ctaLink="mgmedialt@gmail.com"
      />
    </div>
  );
};

export default Team;
