import React from 'react';

import Service from 'components/Service';
import {
  KaraokeIcon,
  SaxophoneIcon,
  PartyIcon,
  PhotoIcon,
} from '../../assets/icons';

import './_services.scss';

const Services = () => (
  <div className="services" id="paslaugos">
    <div className="services__row">
      <Service
        icon={<KaraokeIcon />}
        title="Organizavimas"
        text="Organizuojame ir vedame Vestuves, gimtadienius, įmonių ir studentiškus renginius bei visas kitas Jums svarbias šventes."
      />
      <Service
        icon={<SaxophoneIcon />}
        title="Muzika"
        text="Jums skambės gyvai atliekami lietuviški ir užsienio atlikėjų hitai, neįprastos muzikinės spalvos suteiks saksofono melodija, o vietoje nusėdėti neleis išskirtinė DJ muzika."
      />
    </div>
    <div className="services__row">
      <Service
        icon={<PartyIcon />}
        title="Šventinė aplinka"
        text="Jaudinančias emocijas kurti padės profesionali garso ir apšvietimo technika, kurią priderinsime prie kiekvienos šventės individualiai."
      />
      <Service
        icon={<PhotoIcon />}
        title="Foto"
        text="Profesionalus fotografas įamžins Jūsų renginius ir šventes, kad gražiausios akimirkos truktų ne tik vieną vakarą ir išliktų ne tik Jūsų prisiminimuose."
      />
    </div>
  </div>
);

export default Services;
