import React from 'react';

import Layout from 'components/Layout';
import Services from 'containers/Services';
import Team from 'containers/Team';
import Contact from 'containers/Contact';
import SEO from 'components/SEO';

import '../styles/styles.scss';

const IndexPage = () => (
  <>
    <SEO />
    <Layout>
      <Services />
      <Team />
      <Contact />
    </Layout>
  </>
);

export default IndexPage;
